<template>
	<div>
		<a-descriptions bordered :title="$t(`table.finance.repayment.title1`)" size="default">
			<a-descriptions-item label="Case ID">
				<span>{{ caseId }}</span>
			</a-descriptions-item>
			<a-descriptions-item label="Name">
				<span>{{ fullName }}</span>
			</a-descriptions-item>
			<a-descriptions-item label="Mobile No.">
				<span v-html="mobiles"></span>
			</a-descriptions-item>
			<a-descriptions-item label="Registration No.">
				<span v-html="carPlateNo"></span>
			</a-descriptions-item>
			<a-descriptions-item label="Case Status">
				<span>{{ $t(`table.finance.cloum.statusMap.${status}`) }}</span>
			</a-descriptions-item>
			<!-- <a-descriptions-item :label="$t('table.finance.cloum.loanId')">
				<span>{{ caseId }}</span>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.finance.cloum.payAmount')">
				<a-statistic :precision="2" :value="amount == null ? '' : amount" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.finance.cloum.payType')">
				<span>{{ this.$t(`table.finance.repayment.payTypeMap.${this.transactionTypeEnum.value}`) }}</span>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.finance.cloum.name')">
				<span>{{ fullName }}</span>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.finance.cloum.payID')">
				<span>{{ paymentId }}</span>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.finance.cloum.amountReceived')">
				<a-statistic :precision="2" :value="amount == null ? '' : amount" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.finance.cloum.mobile')">
				<span v-html="mobiles"></span>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.finance.cloum.arrivalTime')">
				<span>{{ transactionDate }}</span>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.finance.cloum.balance')">
				<a @click="SowReason"> <a-statistic :precision="2" :value="extraAmount == null ? '' : extraAmount" /></a>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.finance.cloum.licensePlateNumber')">
				<span v-html="carPlateNo"></span>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.finance.cloum.payCode')">
				<a @click="GoPayCode">{{ $t('page.checkDetail') }}</a>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.finance.cloum.check')">
				<span>{{ needClosedLoan ? $t('page.yes') : $t('page.no') }}</span>
			</a-descriptions-item> -->
		</a-descriptions>
		<a-table
			style="font-size: 12px; margin-bottom: 20px;"
			:columns="repayColumns"
			size="small"
			:rowKey="(record, index) => index"
			:dataSource="repaysList"
			:pagination="false"
			:scroll="{
				x: true
			}"
			bordered
			class="repayments-plan-list-table"
		>
			<template #title>
				<p class="title"> Application Information </p>
			</template>
			<a-statistic slot="amount" slot-scope="text" :precision="2" :value="text === null ? ' - ' : text" />
			<span slot="receipt" slot-scope="obj">
				<template v-if="obj.transactionFiles">
					<viewer v-if="['png', 'jpg', 'jpeg', 'gif'].includes(obj.transactionFiles.split('?')[0].split('.').pop().toLowerCase())">
						<img :src="$Util.getObsImageUrl($store.state.pageState.authObj, obj.transactionFiles)" alt="" width="100" height="50" />
					</viewer>
					<a v-else :href="$Util.getObsImageUrl($store.state.pageState.authObj, obj.transactionFiles)" target="_blank">{{
						obj.transactionFiles.split('/')[obj.transactionFiles.split('/').length - 1]
					}}</a>
				</template>
			</span>
		</a-table>

		<a-descriptions bordered title="" size="default">
			<a-descriptions-item label="Repayment Amount">
				<a-statistic :precision="2" :value="amount == null ? '' : amount" />
			</a-descriptions-item>
			<a-descriptions-item label="Loan(s) to repay/early settle">
				<a-space :size="10">
					<span v-for="v in repayLoan" :key="v">{{ $t(`table.applyInfo.financialLabel.loanMap.${v}`) }}</span>
				</a-space>
			</a-descriptions-item>
		</a-descriptions>
		<repayments-plan-list
			v-if="repayPlan.length > 0 && caseId"
			:repayPlan.sync="repayPlan"
			:insurancePlans.sync="insurancePlans"
			:emergencyPlans.sync="emergencyPlans"
			:overpaidDetail.sync="overpaidDetail"
			style="margin-bottom: 10px"
			:loanType="columnLoanType"
			:type="isEarlyRepay?1: 0"
		></repayments-plan-list>
		<div class="remark">tips: {{ $t('table.finance.cloumRepayment.tips') }}</div>
		<a-button type="primary" v-t.preserve="'operate.refused'" style="margin-right: 30px" @click="Refused"></a-button>
		<a-button type="primary" v-t.preserve="'operate.successed'" @click="Successed"></a-button>
		<a-modal v-drag-modal v-model="RefusedModal.show" :title="$t(`operate.refusedReason`)" @ok="SaveRefused">
			<a-textarea v-model="RefusedModal.msg" :maxLength="300" :auto-size="{ minRows: 4, maxRows: 10 }" />
		</a-modal>
		<audit-record v-if="isShow" :checkHistories="checkHistories"></audit-record>
	</div>
</template>
<script>
import AuditRecord from '@/views/businessComponents/AuditRecord'
import { apiDisburseEarlyInfo } from '@/api/loan'
import { apiLoanTransactionInput, apiProcessHistory, apiLoanTransactionInputPreview, apiHandleTransaction } from '@/api/check'
import RepaymentsPlanList from '@/views/businessComponents/RepaymentsPlanList'
export default {
	data() {
		return {
			id: this.$route.query.id,
			caseId: '',
			loanId: '',
			mobiles: '',
			fullName: '',
			carPlateNo: '',
			status: 1,
			isEarlyRepay: false,
			repaysList: [],
			amount: 0,

			isShow: false,
			checkHistories: [],
			columnLoanType: '',
			repayPlan: [],
			insurancePlans: [],
			emergencyPlans: [],
			overpaidDetail: [],
			RefusedModal: {
				show: false,
				msg: ''
			},
			repayLoan: []
		}
	},
	created() {
		this.$nextTick(() => {
			this.Init()
		})
	},
	computed: {
		repayColumns() {
			return [
				{
					title: 'Type',
					align: 'center',
					customRender: (v, o) => {
						return this.isEarlyRepay?'Early Settlement': 'Repayment'
					}
				},
				{
					title: 'Repayment Type',
					dataIndex: 'transactionTypeEnum',
					align: 'center',
					customRender: (v, o) => {
						return this.$t(`table.finance.repayment.payTypeMap.${v.value}`)
					}
				},
				{
					title: 'Reference ID/Receipt No.',
					dataIndex: 'paymentId',
					align: 'center',
				},
				{
					title: 'Amount Received',
					dataIndex: 'amount',
					align: 'center',
					scopedSlots: { customRender: 'amount' }
				},
				{
					title: 'Actual Payment Time',
					dataIndex: 'transactionDate',
					align: 'center',
				},
				{
					title: 'Repayment Receipt',
					scopedSlots: { customRender: 'receipt' },
					align: 'center'
				},
				{
					title: 'Document Password',
					dataIndex: 'password',
					align: 'center',
				},
			]
		},
	},
	methods: {
		Init() {
			apiLoanTransactionInput(this.id).then((res) => {
				this.isEarlyRepay = res.isEarlyRepay
				if(res.isEarlyRepay){
					this.loanId = res.earlyInfo.loanId
					this.caseId = res.earlyInfo.repays[0].casePrefix + '-' + res.earlyInfo.repays[0].caseId
					this.fullName = res.earlyInfo.repays[0].fullName
					this.status = res.earlyInfo.repays[0].status
					this.carPlateNo = res.earlyInfo.repays[0].carInfos ? res.earlyInfo.repays[0].carInfos.map((item) => item.registrationNumber).join('<br />') : ''
					this.repaysList = res.earlyInfo.repays
					res.earlyInfo.repays.map(item => {
						this.amount += item.amount
					})
					let loanIds = res.earlyInfo.repayLoanIds?res.earlyInfo.repayLoanIds.split(','): []
					apiDisburseEarlyInfo({ loanId: this.loanId }).then(res => {
						if(loanIds.length == 0){
							res.map(item => {
								this.repayLoan.push(item.loanType)
							})
						}else {
							res.map(item => {
								loanIds.map(item2 => {
									if(item2 == item.settlementLoanId){
										this.repayLoan.push(item.loanType)
									}
								})
							})
						}
					})
				} else {
					this.loanId = res.repay.loanId
					this.caseId = res.repay.casePrefix + '-' + res.repay.caseId
					this.fullName = res.repay.fullName
					this.status = res.repay.status
					this.amount = res.repay.amount
					this.mobiles = res.repay.mobiles ? res.repay.mobiles.join('<br />') : ''
					this.carPlateNo = res.repay.carInfos ? res.repay.carInfos.map((item) => item.registrationNumber).join('<br />') : ''
					this.repaysList.push(res.repay)
					this.repayLoan = res.repay.repayLoan?res.repay.repayLoan: []
					if(this.repayLoan.length == 0){
						apiDisburseEarlyInfo({ loanId: this.loanId }).then(res => {
							res.map(item => {
								this.repayLoan.push(item.loanType)
							})
						})
					}
				}

				// this.columnLoanType = res.loanType
				// this.loanId = res.loanId
				// this.caseId = res.casePrefix + '-' + res.caseId
				// this.clientId = res.clientId
				// this.mobileNo = res.mobileNo
				// this.mobiles = res.mobiles ? res.mobiles.join('<br />') : ''
				// this.fullName = res.fullName
				// this.carPlateNo = res.carInfos ? res.carInfos.map((item) => item.registrationNumber).join('<br />') : ''
				// this.paymentId = res.paymentId
				// this.paymentAmount = res.paymentAmount
				// this.transactionTypeEnum = res.transactionTypeEnum
				// this.transactionDate = res.transactionDate
				// this.transactionFiles = res.transactionFiles
				// this.createdDate = res.createdDate
				// this.userId = res.userId
				// this.amount = res.amount
				// this.extraAmount = res.extraAmount
				// this.actualExtraAmount = res.actualExtraAmount
				// this.extraReason = res.extraReason
				// this.needClosedLoan = res.needClosedLoan
				// this.status = res.status
				this.GetRecordList()
			})
			apiLoanTransactionInputPreview({ id: this.id }).then((res) => {
				this.repayPlan = res.loanRepaymentPreviewVos
				this.insurancePlans = res.loanInsurancePreviewVos
				this.emergencyPlans = res.loanEmergencyPreviewVos
				this.overpaidDetail = res.overpaidDetail
			})
		},
		// 查看原因
		SowReason() {
			this.$success({
				title: this.$t('table.finance.repayment.balanceReason'),
				content: this.extraReason
			})
		},
		GoPayCode() {
			let linkurl = this.$Util.getObsImageUrl(this.$store.state.pageState.authObj, this.transactionFiles)
			window.open(linkurl, '_blank')
		},
		// 获取操作记录
		GetRecordList() {
			apiProcessHistory({ loanId: this.loanId }).then((res) => {
				this.checkHistories = res
				this.isShow = true
			})
		},
		// 拒绝平账
		Refused() {
			this.RefusedModal = {
				show: true,
				msg: ''
			}
		},
		// 提交表单
		SaveRefused() {
			let params = {
				pass: false,
				text: this.RefusedModal.msg,
				id: this.id
			}
			if (!params.text) {
				this.$message.error('msg error')
				return false
			}
			apiHandleTransaction(params)
				.then((res) => {
					this.$message.success('success')
					this.RefusedModal.show = false
					this.$store.commit('tabs/delTab', this.$route.fullPath)
					this.$store.commit('tabs/setActiveTab', '/home/finance/repaymentReview')
					this.$router.push('/home/finance/repaymentReview')
				})
				.catch((err) => {
					this.RefusedModal.show = false
				})
		},
		// 平账成功
		Successed() {
			this.$confirm({
				title: this.$t(`table.finance.msg`),
				content: '',
				onOk: () => {
					let params = {
						pass: true,
						id: this.id
					}
					apiHandleTransaction(params)
						.then((res) => {
							this.$message.success('success')
							this.$store.commit('tabs/delTab', this.$route.fullPath)
							this.$store.commit('tabs/setActiveTab', '/home/finance/repaymentReview')
							this.$router.push('/home/finance/repaymentReview')
						})
						.catch((err) => {})
				},
				onCancel() {
					console.log('Cancel')
				}
			})
		}
	},
	components: {
		RepaymentsPlanList,
		AuditRecord
	}
}
</script>
<style scoped lang="less">
.ant-form-item {
	margin-bottom: 10px;
}
.ant-form-item {
	display: flex;
}
.ant-form-item-control-wrapper {
	flex: 1;
}
.ant-table-row.change {
	.link {
		color: #000;
		cursor: pointer;
	}
}
.p_list {
	text-align: center;
}
.link {
	color: #40a9ff;
	cursor: pointer;
}
.remark {
	padding: 10px 0;
	font-size: 16px;
	color: #f56c6c;
}
.repayments-plan-list-table {
	.title {
		font-size: 14px;
		color: rgba(0, 0, 0, 0.85);
		font-weight: bold;
	}
	.p_list {
		text-align: center;
	}
	.link {
		cursor: pointer;
		color: #40a9ff;
	}
	.balance {
		background: #e6f7ff;
	}
}
</style>
